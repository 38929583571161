import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const permissions = store.getters && store.getters.permissions;
    const permissionValues = value;

    const hasPermission = permissions.some((permission) => permissionValues.includes(permission));
    return hasPermission;
  }
  console.error("need roles! Like v-permission=\"['add-user','view-user']\"");
  return false;
}
