<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input
        v-model:value="query"
        :size="size"
        :placeholder="$t('search property name')"
        style="min-width: 200px"
        @change="debounceQuery"
      />
    </div>
    <div class="column">
      <a-button
        v-show="query"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
      "search property name": "search property name",
      "Clear": "Clear"
  },
  "ja":{
    "search property name": "物件名を検索",
    "Clear": "クリア"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'SearchForm',
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      query: undefined,
    };
  },
  mounted() {
    this.query = this.$route.query.name || undefined;
  },
  methods: {
    // eslint-disable-next-line
    debounceQuery: debounce(function () {
      this.$router.push({ query: { name: this.query } });
    }, 600),
    handleClear() {
      this.query = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>
