<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Property') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <PropertyForm
          ref="form"
          :is-submitting="isSubmitting"
          :anshin-accounts="anshinAccounts"
          :neppan-accounts="neppanAccounts"
          @submit="handleSubmit"
        />
        <a-alert v-if="property" type="success">
          <template #message>
            <span v-html="$t('property-added', { name: property.name })" />
            <router-link to="/properties"> View properties page </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add New Property": "Add New Property",
"property-added": "property <strong>{name}</strong> added,",
"View properties page": "View properties page"
},
"ja": {
"Add New Property": "物件追加",
"property-added": "追加物件<strong>{name}</strong>,",
"View properties page": "ビュー物件 ページ"
}
}
</i18n>

<script>
import PropertyForm from '@/views/properties/components/PropertyForm';

export default {
  name: 'Add',
  components: { PropertyForm },
  data() {
    return {
      property: undefined,
      isSubmitting: false,
      error: '',
      anshinAccounts: [],
      neppanAccounts: [],
    };
  },
  created() {
    this.$store.dispatch('properties/listAnshinAccounts').then((res) => {
      this.anshinAccounts = res.list;
    });
    this.$store.dispatch('properties/listNeppanAccounts', { availableOnly: true }).then((res) => {
      this.neppanAccounts = res.list;
    });
  },
  methods: {
    handleSubmit(data) {
      this.isSubmitting = true;
      this.property = undefined;
      this.error = '';
      this.$store
        .dispatch('properties/add', data)
        .then(() => {
          this.isSubmitting = false;
          this.property = data;
          this.$refs.form.resetFields();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.error = err.response.data.errors.message;
          }
          this.isSubmitting = false;
        });
    },
  },
};
</script>
