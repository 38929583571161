<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('Anshin Account')" name="anshinAccount">
      <a-select
        v-model:value="form.anshinAccountId"
        :size="size"
        @change="handleAnshinAccountChange"
      >
        <a-select-option v-for="item in anshinAccounts" :key="item.id" :value="item.id">
          {{ item.domain }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Anshin Property')" name="anshinProperty">
      <a-select
        v-model:value="form.anshinPropertyId"
        :size="size"
        :loading="isFetchingAnshin"
        @change="handleAnshinPropertyChange"
      >
        <a-select-option
          v-for="item in anshinProperties"
          :key="item.accommodationsGroupId"
          :value="item.accommodationsGroupId"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('New Property Code')" name="newPropertyCode">
      <a-input v-model:value.trim="form.newPropertyCode" :size="size" type="text" />
    </a-form-item>

    <a-form-item class="column is-12" label="Neppan Property Code" name="neppanPropertyCode">
      <a-select
        v-model:value="form.neppanPropertyCode"
        :size="size"
        @change="handleNeppanAccountChange"
      >
        <a-select-option
          v-for="item in neppanAccounts"
          :key="item.code"
          :value="item.code"
        >
          {{ item.code }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('Property Abbreviation')"
      name="propertyAbbreviation"
    >
      <a-input v-model:value="form.abbreviation" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name')" name="name">
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name (English)')" name="nameEn">
      <a-input v-model:value="form.nameEn" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property Name (Japanese)')" name="nameKana">
      <a-input v-model:value="form.nameKana" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Main Brand')" name="mainBrand">
      <a-input v-model:value="form.mainBrand" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Sub Brand')" name="subBrand">
      <a-input v-model:value="form.subBrand" :size="size" type="text" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Property Size')" name="size">
      <a-select v-model:value="form.size" :size="size">
        <a-select-option value="small">
          {{ $t('Small') }}
        </a-select-option>
        <a-select-option value="large">
          {{ $t('Large') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-6" :label="$t('Tablet')" name="tablet.tablet">
      <a-select v-model:value="form.tablet.tablet" :size="size">
        <a-select-option value="Yes">
          Yes
        </a-select-option>
        <a-select-option value="No">
          No
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-6" :label="$t('Tablet Mode')" name="tablet.mode">
      <a-select v-model:value="form.tablet.mode" :size="size">
        <a-select-option value="Lobby">
          {{ $t('Lobby') }}
        </a-select-option>
        <a-select-option value="Room">
          {{ $t('Room') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Tablet Location')" name="tablet.location">
      <a-input v-model:value="form.tablet.location" :size="size" type="text" />
    </a-form-item>

    <div class="column is-12">
      <div class="form-title">
        {{ $t('Address') }}
      </div>
    </div>
    <a-form-item class="column is-6" :label="$t('Postal Code')" name="postalCode">
      <a-input v-model:value="form.postalCode" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-6" :label="$t('Prefecture')" name="prefecture">
      <a-input v-model:value="form.prefecture" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Address')" name="address">
      <a-input v-model:value="form.address" :size="size" type="text" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Assignment Type')" name="assignmentType">
      <a-select v-model:value="form.assignmentType" :size="size">
        <a-select-option value="auto">
          {{ $t('Auto') }}
        </a-select-option>
        <a-select-option value="manual">
          {{ $t('Manual') }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "New Property Code": "New Property Code",
    "Property Abbreviation": "Property Abbreviation",
    "Property Name": "Property Name",
    "Property Name (English)": "Property Name (English)",
    "Property Name (Japanese)": "Property Name (Japanese)",
    "Main Brand": "Main Brand",
    "Sub Brand": "Sub Brand",
    "Property Size": "Property Size",
    "Small": "Small",
    "Large": "Large",
    "Tablet": "Tablet",
    "Tablet Mode": "Tablet Mode",
    "Lobby": "Lobby",
    "Room": "Room",
    "Tablet Location": "Tablet Location",
    "Address": "Address",
    "Postal Code": "Postal Code",
    "Prefecture": "Prefecture",
    "Assignment Type": "Assignment Type",
    "Auto": "Auto",
    "Manual": "Manual",
    "Save": "Save",
    "Anshin Property": "Anshin Property",
    "Anshin Account": "Anshin Account",
    "New property code is required": "New property code is required",
    "Property name is required": "Property name is required",
    "Address is required": "Address is required",
    "Postal code is required": "Postal code is required",
    "Prefecture is required": "Prefecture is required"
  },
  "ja": {
    "New Property Code": "新規物件コード",
    "Property Abbreviation": "物件略称",
    "Property Name": "物件正式名称",
    "Property Name (English)": "物件正式名称（英語）",
    "Property Name (Japanese)": "物件正式名称（日本語）",
    "Main Brand": "メインブランド",
    "Sub Brand": "サブブランド",
    "Property Size": "プロパティサイズ",
    "Small": "小さな",
    "Large": "大きい",
    "Tablet": "タブレット",
    "Tablet Mode": "タブレットモード",
    "Lobby": "ロビー",
    "Room": "部屋",
    "Tablet Location": "タブレット場所",
    "Address": "施設住所",
    "Postal Code": "施設郵便番号",
    "Prefecture": "都道府県",
    "Assignment Type": "割り当てのタイプ",
    "Auto": "自動",
    "Manual": "マニュアル",
    "Save": "保存",
    "Anshin Property": "あんしんプロパティ",
    "Anshin Account": "あんしんアカウント",
    "New property code is required": "新規物件コードは必須です",
    "Property name is required": "物件正式名称は必須です",
    "Address is required": "施設住所は必須です",
    "Postal code is required": "施設郵便番号は必須です",
    "Prefecture is required": "都道府県は必須です"
  }
}
</i18n>

<script>
export default {
  name: 'PropertyForm',
  components: {},
  props: {
    isSubmitting: {
      type: Boolean,
    },
    anshinAccounts: {
      type: Array,
      default: () => [],
    },
    neppanAccounts: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit'],
  data() {
    return {
      isFetchingAnshin: false,
      anshinProperties: [],
      dateFormat: 'YYYY-MM-DD',
      form: {
        newPropertyCode: '',
        neppanPropertyCode: undefined,
        abbreviation: '',
        name: '',
        nameEn: '',
        nameKana: '',
        mainBrand: '',
        subBrand: '',
        size: 'small',
        tablet: {
          tablet: '',
          mode: '',
          location: '',
        },
        postalCode: '',
        prefecture: '',
        address: '',
        anshinPropertyId: undefined,
        maxGuests: undefined,
        assignmentType: 'auto',
      },
      rules: {
        newPropertyCode: [
          {
            required: true,
            message: this.$t('New property code is required'),
            trigger: ['change', 'blur'],
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('Property name is required'),
            trigger: ['change', 'blur'],
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('Address is required'),
            trigger: ['change', 'blur'],
          },
        ],
        postalCode: [
          {
            required: true,
            message: this.$t('Postal code is required'),
            trigger: ['change', 'blur'],
          },
        ],
        prefecture: [
          {
            required: true,
            message: this.$t('Prefecture is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form });
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    handleAnshinPropertyChange(val) {
      const selected = this.anshinProperties.find((p) => p.accommodationsGroupId === val);
      this.form.name = selected.name;
      // this.form.maxGuests = selected.maxGuests
      // this.form.address = selected.address
      // this.form.tablet.tablet = selected.smartlock ? 'Yes' : 'No'
    },
    handleAnshinAccountChange(val) {
      this.isFetchingAnshin = true;
      const payload = {
        params: {
          availableOnly: true,
        },
        anshinAccountId: val,
      };
      this.$store.dispatch('properties/listAnshinGroups', payload).then((res) => {
        this.anshinProperties = res.list;
        this.isFetchingAnshin = false;
      });
    },
    handleNeppanAccountChange(val) {
      this.form.neppanPropertyCode = val;
    },
  },
};
</script>
