<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Properties') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm />
          </div>
          <div v-permission="['add-property']" class="column is-narrow">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" to="/properties/add">
              {{ $t('Add New Property') }}
            </router-link>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="properties"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #abbreviation="{ record }">
            {{ record.abbreviation }}
          </template>
          <template #assignmentType="{ record }">
            {{ $filters.capitalizeFirst(record.assignmentType) }}
          </template>
          <template #roomAssignment="{ record }">
            <a @click.stop.prevent="roomAssignment(record)">Room Assignment</a>
          </template>
          <template #status="{ record }">
            <div @click.stop>
              <a-switch
                v-model:checked="record.isActive"
                :disabled="!canEditProperty"
                @change=" (status) => { updatePropertyStatus(status, record); }"
              />
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Properties": "Properties",
    "Add New Property": "Add New Property",
    "Property": "Property",
    "Assignment Type": "Assignment Type",
    "Room Assignment": "Room Assignment",
    "Status": "Status"
  },
  "ja": {
    "Properties": "物件",
    "Add New Property": "物件追加",
    "Property": "物件",
    "Assignment Type": "割り当てのタイプ",
    "Room Assignment": "清掃員の割り当て",
    "Status": "ステータス"
  }
}
</i18n>

<script>
import checkPermission from '@/utils/permission';
import pagination from '@/components/mixins/pagination';
import SearchForm from '@/views/properties/components/SearchForm';

export default {
  name: 'Properties',
  components: {
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      properties: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'abbreviation',
          slots: { customRender: 'abbreviation' },
        },
        {
          title: this.$t('Assignment Type'),
          dataIndex: 'assignmentType',
          key: 'assignmentType',
          slots: { customRender: 'assignmentType' },
        },
        {
          title: this.$t('Room Assignment'),
          dataIndex: 'roomAssignment',
          key: 'roomAssignment',
          slots: { customRender: 'roomAssignment' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
      ],
    };
  },
  computed: {
    canEditProperty() {
      return checkPermission(['modify-property']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'properties') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('properties/list', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.properties = list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updatePropertyStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('properties/update', { id: record.newPropertyCode, isActive: status })
        .finally(() => {
          this.fetchData();
        });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'properties',
          });
          this.$router.push({ name: 'property-overview', params: { id: record.newPropertyCode } });
        },
      };
    },
    roomAssignment(record) {
      this.$router.push({ name: 'property-room', params: { id: record.id } });
    },
  },
};
</script>
